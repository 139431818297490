<template>
  <Dialog
    maximizable
    class="history-dialog"
    :class="{ 'p-dialog-maximized': mobileSize }"
    header="&nbsp;"
    modal
    :showHeader="true"
  >
    <h1 class="header h-1">{{ t('clients.history') }}</h1>
    <div class="progress-container" v-if="isLoading">
      <ProgressSpinner />
    </div>
    <EmptyState
      v-if="isEmpty && !isLoading"
      :description="t('customer_history_is_empty')"
    />
    <ClientHistoryDialogDetail
      v-for="detail in historyList"
      :key="detail.id"
      :detail="detail"
    />
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import { useBreakpoints } from '@bd/components'
import { useI18n } from 'vue-i18n'
import ClientHistoryDialogDetail from './ClientHistoryDialogDetail.vue'
import { TransactionHistoryByCustomerDto } from '@bd/api/common/types/transaction'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'

export default defineComponent({
  name: 'ClientHistoryDialog',
  components: {
    Dialog,
    ClientHistoryDialogDetail,
    ProgressSpinner,
    EmptyState,
  },
  props: {
    historyList: {
      type: Array as PropType<TransactionHistoryByCustomerDto[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    const { width, sizes } = useBreakpoints()
    const mobileSize = computed(() => width.value <= sizes.md)

    return {
      t,
      mobileSize,
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  margin-bottom: 30px;
}
.dialog-content {
  margin: 1rem;
  margin-bottom: 2rem;
}

:deep(.history-dialog) {
  width: min(100vw, 488px);
  .p-dialog-header-maximize {
    display: none;
  }
  .p-dialog-content {
    padding: 0 77px 40px;
    @include breakpoint-down(xs) {
      padding: 0 25px 20px;
    }
  }
}

.chip-accept {
  color: $jungle-green;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 14px;
  background: rgba($jungle-green, 0.2);
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 50px;
}
</style>
