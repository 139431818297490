import { TileItem } from '@bd/components/TileList/types'
import { ClientDetailsDto } from '@bd/api'
import { i18n } from '@bd/agent/plugins/vue-i18n-next-plugin'
import { LOCALISATION_SETTINGS } from '@bd/agent/config'
import { computed, ComputedRef } from 'vue'

const t = i18n.global.t
const { currencyDecimal } = LOCALISATION_SETTINGS

const formatRangeValues = (min?: number, max?: number) => {
  if (!min && !max) {
    return null
  }
  if (!min) {
    return `${t('clients.to')} ${max}`
  }
  if (!max) {
    return `${t('clients.from')} ${min}`
  }
  if (min && max) {
    return `${min} - ${max}`
  }
}

export const clientTopItems = (
  clientRef: ComputedRef<ClientDetailsDto | undefined>,
) =>
  computed<TileItem[]>(() => {
    if (!clientRef.value) {
      return []
    }

    const client = clientRef.value
    const city = client?.customerCity || ''
    const district = client?.customerDistrict || ''

    const location = () => {
      if (city || district) return city + ' ' + district
    }

    return [
      {
        name: t('email'),
        icon: require('@bd/assets/icons/mail.svg'),
        value: client?.email,
      },
      {
        name: t('phone'),
        icon: require('@bd/assets/icons/phone.svg'),
        value: client?.phoneNumber,
      },
      {
        name: t('clients.wanted_location'),
        icon: require('@bd/assets/icons/location.svg'),
        value: location(),
      },
      {
        name: t('clients.form.area'),
        icon: require('@bd/assets/icons/square.svg'),
        value: formatRangeValues(
          client?.customerAreaMin,
          client?.customerAreaMax,
        ),
        suffix: 'm2',
      },
      {
        name: t('clients.form.price'),
        icon: require('@bd/assets/icons/price.svg'),
        value: formatRangeValues(
          client?.customerPriceMin,
          client?.customerPriceMax,
        ),
        suffix: currencyDecimal,
      },
      {
        name: t('clients.form.market'),
        icon: require('@bd/assets/icons/market.svg'),
        value: client?.customerMarket
          ? t(`enums.propertyMarket.${client.customerMarket}`)
          : null,
      },
    ].filter(({ value }) => value)
  })

export const customerHistoryPayload = {
  pageIndex: 0,
  pageSize: 50,
}
