
import { defineComponent, PropType, computed } from 'vue'
import ClientHistoryDialogDate from './ClientHistoryDialogDate.vue'
import { useI18n } from 'vue-i18n'
import { LOCALISATION_SETTINGS } from '@bd/agent/config'
import { getFormattedPrice } from '@bd/helpers'
import { TransactionHistoryByCustomerDto } from '@bd/api/common/types/transaction'

export default defineComponent({
  name: 'ClientHistoryDialogDetail',
  components: { ClientHistoryDialogDate },
  props: {
    detail: {
      type: Object as PropType<TransactionHistoryByCustomerDto>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS
    const user = computed(() => {
      const customer = props.detail.customer
      const seller = props.detail.seller
      return {
        customer: `${customer.firstName} ${customer.lastName}`,
        seller: `${seller.firstName} ${seller.lastName}`,
      }
    })

    return { t, currency, locale, getFormattedPrice, user }
  },
})
