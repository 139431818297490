
import { defineComponent, computed, PropType } from 'vue'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import { useBreakpoints } from '@bd/components'
import { useI18n } from 'vue-i18n'
import ClientHistoryDialogDetail from './ClientHistoryDialogDetail.vue'
import { TransactionHistoryByCustomerDto } from '@bd/api/common/types/transaction'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'

export default defineComponent({
  name: 'ClientHistoryDialog',
  components: {
    Dialog,
    ClientHistoryDialogDetail,
    ProgressSpinner,
    EmptyState,
  },
  props: {
    historyList: {
      type: Array as PropType<TransactionHistoryByCustomerDto[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    const { width, sizes } = useBreakpoints()
    const mobileSize = computed(() => width.value <= sizes.md)

    return {
      t,
      mobileSize,
    }
  },
})
