
import { computed, defineComponent, ref } from 'vue'
import {
  PageContent,
  UserType,
  TileList,
  ConfirmDialog,
  useResourceDataState,
  ClientNoteSaveDialog,
  ClientNote,
  ClientNoteMessages,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/agent/store'
import { CLIENT_BOTTOM_ITEMS } from '@bd/agent/config'
import { useRoute } from 'vue-router'
import ClientDescription from '@bd/agent/components/Client/ClientDescription.vue'
import ClientHistoryDialog from '@bd/agent/components/Client/ClientHistoryDialog.vue'
import ClientHeader from '@bd/agent/components/Client/ClientHeader.vue'
import { clientTopItems, customerHistoryPayload } from './index'
import { UserRole } from '@bd/api'
import { useToast } from 'primevue/usetoast'
import { deleteNote } from '@bd/api/common/clients-management-api'

export default defineComponent({
  name: 'ClientDetails',
  components: {
    PageContent,
    ClientDescription,
    TileList,
    ClientNote,
    ClientHistoryDialog,
    ClientHeader,
    ConfirmDialog,
    ClientNoteSaveDialog,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const toast = useToast()

    const showClientHistoryDialog = ref(false)
    const showConfirmationDialog = ref(false)
    const showClientNoteSaveDialog = ref(false)
    const noteToDelete = ref<number>()
    const historyList = computed(() => {
      return store.state.transactionHistory?.transactionHistory
    })

    const fetchClient = async () => {
      await store.dispatch('clients/clientDetails', route.params.id)
    }

    const onNoteDelete = async (noteId: number) => {
      noteToDelete.value = noteId
      showConfirmationDialog.value = true
    }

    const onDialogConfirm = async () => {
      try {
        await deleteNote(noteToDelete.value!)
        toast.add(ClientNoteMessages.deleteSuccess)
      } catch (error) {
        toast.add(ClientNoteMessages.deleteError)
        return
      }
      await fetchClient()
      showConfirmationDialog.value = false
    }

    const onDialogReject = () => {
      showConfirmationDialog.value = false
    }

    const openNoteDialog = () => {
      showClientNoteSaveDialog.value = true
    }

    const client = computed(() => {
      return store.state.clients?.client
    })

    const bottomOptions = {
      iconWrapperColor: 'jungle-green-icon',
      iconClass: 'colorful heliotrope',
    }

    const clientColorPrefix = computed(() => {
      const isSeller = client.value?.roles.includes(UserRole.SELLER)
      if (isSeller) {
        return client.value?.sellerDeveloper ? 'jungle-green-' : 'dodger-blue-'
      }
      return 'zest-'
    })

    const topOptions = computed(() => {
      const iconWrapperColor = clientColorPrefix.value + 'icon'
      const iconClass = 'colorful ' + clientColorPrefix.value + 'color'
      return {
        iconWrapperColor,
        iconClass,
      }
    })

    const topItems = clientTopItems(client)

    const customerHistory = async () => {
      const payload = {
        ...customerHistoryPayload,
        customerId: client.value?.userId,
      }
      await store.dispatch(
        'transactionHistory/transactionHistoryCustomer',
        payload,
      )
      showClientHistoryDialog.value = true
    }

    const { isLoading, isEmpty } = useResourceDataState(
      historyList,
      (res) => !!(res && res.length),
    )

    const onNoteAdded = () => {
      showClientNoteSaveDialog.value = false
      fetchClient()
    }

    return {
      UserType,
      t,
      client,
      CLIENT_BOTTOM_ITEMS,
      bottomOptions,
      topOptions,
      topItems,
      showClientHistoryDialog,
      onNoteDelete,
      showConfirmationDialog,
      onDialogConfirm,
      onDialogReject,
      customerHistory,
      historyList,
      isLoading,
      isEmpty,
      showClientNoteSaveDialog,
      openNoteDialog,
      onNoteAdded,
    }
  },
})
