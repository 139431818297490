<template>
  <div class="description-wrapper">
    <p class="body-sm">
      {{ description }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClientDescription',
  props: {
    description: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.description-wrapper {
  @include flex(space-around, flex-start, column);
  height: 60px;
  margin: 20px 0;
  width: 100%;
  @include breakpoint-down(md) {
    background: white;
    padding: 25px;
  }
}
</style>
