<template>
  <div class="transaction-item">
    <div style="transaction-item-content">
      <p class="text-normal" v-if="detail.type === 'BUYER_TO_SELLER'">
        <span class="user-text">
          {{ user.customer }}
        </span>
        {{ t('price_proposal_was_made') }}
        <span class="user-text">{{ user.seller }}</span>
      </p>
      <p class="text-normal" v-else>
        <span class="user-text">{{ user.seller }} </span>
        {{ t('price_proposal_was_made') }}
        <span class="user-text">{{ user.customer }} </span>
      </p>
    </div>
    <ClientHistoryDialogDate :date="detail.date" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import ClientHistoryDialogDate from './ClientHistoryDialogDate.vue'
import { useI18n } from 'vue-i18n'
import { LOCALISATION_SETTINGS } from '@bd/agent/config'
import { getFormattedPrice } from '@bd/helpers'
import { TransactionHistoryByCustomerDto } from '@bd/api/common/types/transaction'

export default defineComponent({
  name: 'ClientHistoryDialogDetail',
  components: { ClientHistoryDialogDate },
  props: {
    detail: {
      type: Object as PropType<TransactionHistoryByCustomerDto>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS
    const user = computed(() => {
      const customer = props.detail.customer
      const seller = props.detail.seller
      return {
        customer: `${customer.firstName} ${customer.lastName}`,
        seller: `${seller.firstName} ${seller.lastName}`,
      }
    })

    return { t, currency, locale, getFormattedPrice, user }
  },
})
</script>

<style lang="scss" scoped>
$padding-size: 1rem;

.transaction-item {
  @include flex(space-between, center);
  margin: $padding-size;
  color: $comet;
  border-bottom: $divider-border;
  padding-bottom: 1rem;
  &-content {
    min-width: 300px;
  }
  &:last-child {
    border-bottom: none;
  }
}
.price-text {
  font-weight: 600;
  font-size: 1.0625rem;
  color: $port-gore;
}

.text-normal {
  font-size: 0.875rem;
}

.user-text {
  line-height: 19px;
  font-weight: 600;
  color: $port-gore;
}
</style>
