
import { defineComponent, PropType, toRefs } from 'vue'
import { LocalIsoDate } from '@bd/helpers'
import { useFormattedDayAndMonth } from '@bd/components'

export default defineComponent({
  name: 'ClientHistoryDialogDate',
  props: {
    date: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
  },
  setup(props) {
    const { date } = toRefs(props)
    const dayAndMonth = useFormattedDayAndMonth(date)
    return { dayAndMonth }
  },
})
