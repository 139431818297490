
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClientDescription',
  props: {
    description: {
      type: String,
    },
  },
})
