<template>
  <PageContent
    class="custom-page-content"
    showMobileHeader
    :headerLabel="t('clients.details')"
  >
    <ClientHeader v-if="client" :client="client" />
    <div class="client-description change-mobile-order">
      <div class="list-content client-description-title">
        <p class="h-4">{{ t('clients.description') }}</p>
      </div>
      <div class="list-content">
        <ClientDescription
          v-if="client?.description"
          :description="client.description"
        />
      </div>
    </div>
    <div class="list">
      <div class="list-content list-top">
        <TileList
          v-for="item in topItems"
          :key="item.name"
          :item="item"
          :opts="topOptions"
          type="advanced"
        />
      </div>

      <div class="list-content list-bottom">
        <TileList
          class="pointer"
          v-for="item in CLIENT_BOTTOM_ITEMS"
          :key="item.name"
          :item="item"
          :opts="bottomOptions"
          @click="customerHistory"
          type="simple"
        />
      </div>
    </div>
    <div class="client-notes change-mobile-order">
      <div class="list-content client-notes-title">
        <p class="h-4">{{ t('clients.notes') }}</p>
        <Button
          class="p-button-secondary add-note-btn desktop p-button-rounded"
          icon="pi pi-plus"
          :label="t('clients.add_note')"
          @click="openNoteDialog"
        />
      </div>
      <div class="list-content">
        <div class="client-notes-list" v-if="client?.notes.length">
          <ClientNote
            v-for="note in client.notes"
            :key="note.noteId"
            :note="note"
            @delete="onNoteDelete"
          />
        </div>
        <div class="empty-state" v-else>
          <h2 class="h-2 text-center">{{ t('clients.notes_empty') }}</h2>
        </div>
      </div>
      <div class="button-container">
        <Button
          class="p-button-secondary add-note-btn mobile p-button-rounded"
          icon="pi pi-plus"
          :label="t('clients.add_note')"
          @click="openNoteDialog"
        />
      </div>
    </div>
    <ClientHistoryDialog
      v-model:visible="showClientHistoryDialog"
      :isLoading="isLoading"
      :isEmpty="isEmpty"
      :historyList="historyList"
    />
    <ConfirmDialog
      v-model:visible="showConfirmationDialog"
      title=""
      :description="t('clients.note_remove_confirmation')"
      @confirm="onDialogConfirm"
      @reject="onDialogReject"
    />
    <ClientNoteSaveDialog
      v-if="client"
      v-model:visible="showClientNoteSaveDialog"
      :clientId="client.userId"
      @noteAdd="onNoteAdded"
    />
  </PageContent>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import {
  PageContent,
  UserType,
  TileList,
  ConfirmDialog,
  useResourceDataState,
  ClientNoteSaveDialog,
  ClientNote,
  ClientNoteMessages,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/agent/store'
import { CLIENT_BOTTOM_ITEMS } from '@bd/agent/config'
import { useRoute } from 'vue-router'
import ClientDescription from '@bd/agent/components/Client/ClientDescription.vue'
import ClientHistoryDialog from '@bd/agent/components/Client/ClientHistoryDialog.vue'
import ClientHeader from '@bd/agent/components/Client/ClientHeader.vue'
import { clientTopItems, customerHistoryPayload } from './index'
import { UserRole } from '@bd/api'
import { useToast } from 'primevue/usetoast'
import { deleteNote } from '@bd/api/common/clients-management-api'

export default defineComponent({
  name: 'ClientDetails',
  components: {
    PageContent,
    ClientDescription,
    TileList,
    ClientNote,
    ClientHistoryDialog,
    ClientHeader,
    ConfirmDialog,
    ClientNoteSaveDialog,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const toast = useToast()

    const showClientHistoryDialog = ref(false)
    const showConfirmationDialog = ref(false)
    const showClientNoteSaveDialog = ref(false)
    const noteToDelete = ref<number>()
    const historyList = computed(() => {
      return store.state.transactionHistory?.transactionHistory
    })

    const fetchClient = async () => {
      await store.dispatch('clients/clientDetails', route.params.id)
    }

    const onNoteDelete = async (noteId: number) => {
      noteToDelete.value = noteId
      showConfirmationDialog.value = true
    }

    const onDialogConfirm = async () => {
      try {
        await deleteNote(noteToDelete.value!)
        toast.add(ClientNoteMessages.deleteSuccess)
      } catch (error) {
        toast.add(ClientNoteMessages.deleteError)
        return
      }
      await fetchClient()
      showConfirmationDialog.value = false
    }

    const onDialogReject = () => {
      showConfirmationDialog.value = false
    }

    const openNoteDialog = () => {
      showClientNoteSaveDialog.value = true
    }

    const client = computed(() => {
      return store.state.clients?.client
    })

    const bottomOptions = {
      iconWrapperColor: 'jungle-green-icon',
      iconClass: 'colorful heliotrope',
    }

    const clientColorPrefix = computed(() => {
      const isSeller = client.value?.roles.includes(UserRole.SELLER)
      if (isSeller) {
        return client.value?.sellerDeveloper ? 'jungle-green-' : 'dodger-blue-'
      }
      return 'zest-'
    })

    const topOptions = computed(() => {
      const iconWrapperColor = clientColorPrefix.value + 'icon'
      const iconClass = 'colorful ' + clientColorPrefix.value + 'color'
      return {
        iconWrapperColor,
        iconClass,
      }
    })

    const topItems = clientTopItems(client)

    const customerHistory = async () => {
      const payload = {
        ...customerHistoryPayload,
        customerId: client.value?.userId,
      }
      await store.dispatch(
        'transactionHistory/transactionHistoryCustomer',
        payload,
      )
      showClientHistoryDialog.value = true
    }

    const { isLoading, isEmpty } = useResourceDataState(
      historyList,
      (res) => !!(res && res.length),
    )

    const onNoteAdded = () => {
      showClientNoteSaveDialog.value = false
      fetchClient()
    }

    return {
      UserType,
      t,
      client,
      CLIENT_BOTTOM_ITEMS,
      bottomOptions,
      topOptions,
      topItems,
      showClientHistoryDialog,
      onNoteDelete,
      showConfirmationDialog,
      onDialogConfirm,
      onDialogReject,
      customerHistory,
      historyList,
      isLoading,
      isEmpty,
      showClientNoteSaveDialog,
      openNoteDialog,
      onNoteAdded,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.custom-page-content) {
  background: $white;
  height: 100%;
  @include breakpoint-down(md) {
    margin-bottom: 2rem;
  }
}

.change-mobile-order {
  @include breakpoint-down(md) {
    order: 1;
  }
}

.list {
  padding: 4rem 0;
  background: $white;
  @include breakpoint-down(md) {
    padding: 1.875rem 0;
  }
  &-content {
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;

    @include breakpoint-up(lg) {
      max-width: 60%;
    }
    @include breakpoint-down(lg) {
      max-width: 90%;
    }
    @include breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-bottom {
    @include breakpoint-down(md) {
      border-top: $divider-border;
    }
  }
}

.client-notes {
  background: $alabaster;
  padding-top: 3rem;
  &-title {
    margin-bottom: 0.75rem;
    @include flex($align: baseline, $justify: space-between);
  }
  padding-bottom: 2rem;
}

.client-description {
  padding-top: 3rem;
  &-title {
    margin-bottom: 0.75rem;
  }
  @include breakpoint-down(md) {
    background: $alabaster;
  }
}

:deep(.note-wrapper) {
  margin: 0;
  &:not(:first-of-type) {
    margin-left: 1rem;
    @include breakpoint-down(md) {
      margin: 1rem 0 0 0;
    }
  }
  &:not(:last-of-type) {
    margin-right: 1rem;
    @include breakpoint-down(md) {
      margin: 0 0 1rem 0;
    }
  }
}
:deep(.description-wrapper) {
  margin: 0;
}
.button-container {
  @include flex;
  margin-top: 2rem;
  @include breakpoint-up(md) {
    display: none;
  }
}
.add-note-btn {
  padding: 0 1rem;
  &.desktop {
    @include breakpoint-down(md) {
      display: none;
    }
  }
  &.mobile {
    width: min(50%, 250px);
  }
}
.client-notes-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  width: 100%;
}
.empty-state {
  width: 100%;
  margin: 1.5rem 0;
}
</style>
