
import { defineComponent, PropType, computed } from 'vue'
import { UserProfileBanner, UserType } from '@bd/components'
import { UserRole } from '@bd/api'
import DashboardProfileButton from '@bd/agent/components/Dashboard/DashboardProfileButton.vue'
import { useI18n } from 'vue-i18n'
import { ClientDetailsDto } from '@bd/api'
import { useRouter } from 'vue-router'
import {
  CalendarEventAttendee,
  EventBuyerSelectPayload,
} from '@bd/store-modules/types'
import { useAppStore } from '@bd/agent/store'
import { UserDto } from '@bd/api'

export default defineComponent({
  name: 'ClientHeader',
  components: { UserProfileBanner, DashboardProfileButton },
  props: {
    client: {
      type: Object as PropType<ClientDetailsDto>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const router = useRouter()
    const store = useAppStore()
    const typeOfUser = computed(() => {
      const client = props.client
      const clientRoles = props.client.roles
      if (clientRoles.includes(UserRole.SELLER)) {
        return client.sellerDeveloper ? UserType.Developer : UserType.Seller
      }
      return UserType.Buyer
    })

    const mappedClient = computed<UserDto>(() => {
      const {
        userId,
        displayableId,
        email,
        firstName,
        lastName,
        phoneNumber,
        roles,
        avatarUrl,
      } = props.client

      return {
        id: userId,
        displayableId,
        email,
        firstName,
        lastName,
        phoneNumber,
        roles,
        avatarUrl,
      }
    })

    const createBuyerMeeting = async () => {
      const buyer: CalendarEventAttendee = {
        id: props.client.userId,
        firstName: props.client.firstName,
        lastName: props.client.lastName,
        phoneNumber: props.client.phoneNumber,
        displayableId: props.client.displayableId,
      }
      const payload: EventBuyerSelectPayload = {
        buyer,
        keepBuyer: true,
      }
      await store.dispatch('calendarEvent/setSelectedBuyer', payload)
      router.push({ name: 'CalendarEventNew' })
    }

    return { t, typeOfUser, createBuyerMeeting, mappedClient, UserRole }
  },
})
